import { Widget } from '@typeform/embed-react';
import axios from 'axios';
import capitalize from 'lodash/capitalize';
import pluralize from 'pluralize';
import React, {
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useEffectOnce, usePrevious } from 'react-use';
import { type SWRResponse } from 'swr';
import useSWRImmutable from 'swr/immutable';
import { useSnapshot } from 'valtio';

import {
  type EnumsGamePackRecommendationScenario,
  EnumsOneTimePurchaseUpsellPromoCode,
} from '@lp-lib/api-service-client/public';

import { usePostGameAnalytics } from '../../../analytics/postGame';
import { useOnDGameAnalytics } from '../../../analytics/useOndGameAnalytics';
import config from '../../../config';
import {
  getFeatureQueryParamArray,
  useFeatureQueryParam,
} from '../../../hooks/useFeatureQueryParam';
import { useLiveCallback } from '../../../hooks/useLiveCallback';
import { useIsCoordinator, useMyInstance } from '../../../hooks/useMyInstance';
import { useTimeout } from '../../../hooks/useTimeout';
import { useHiddenFieldsSnapShot } from '../../../pages/Audience/OffBoarding/Typeform';
import { GamePackCardBottomAccessory } from '../../../pages/GamePack/GamePackCardBottomAccessory';
import { apiService } from '../../../services/api-service';
import { NotificationType, SessionMode } from '../../../types';
import { type GamePack } from '../../../types/game';
import { BrowserTimeoutCtrl } from '../../../utils/BrowserTimeoutCtrl';
import { uuidv4 } from '../../../utils/common';
import { useAwaitFullScreenConfirmCancelModal } from '../../ConfirmCancelModalContext';
import { ArrowRightIcon, DoubleRightArrow } from '../../icons/Arrows';
import { RefreshIcon } from '../../icons/RefreshIcon';
import { useLayoutAnchorRect } from '../../LayoutAnchors/LayoutAnchors';
import { Loading } from '../../Loading';
import { useNotificationDataSourceRedux } from '../../Notification/Context';
import {
  useOndGameUIControl,
  useOndGameUIControlState,
} from '../../OnDGameUIControl';
import { OTPUpSellModal } from '../../OneTimePurchase/OTPUpSellModal';
import {
  OrgSubscriptionUpgradeButton,
  useMyOrganizationFeatureChecker,
} from '../../Organization';
import { useAmICohost, useParticipant } from '../../Player';
import { useTriggerBookNowWithGameSessionGamePack } from '../../Product/BookNow';
import { useStreamSessionId } from '../../Session';
import {
  SimpleCarousel,
  useSimpleCarouselControl,
} from '../../SimpleCarousel/SimpleCarousel';
import {
  useMyClientIdGetter,
  useVenueDerivedSettings,
  useVenueUrlOpener,
} from '../../Venue';
import {
  useGameHostingCoordinator,
  useGameHostingCoordinatorGetter,
} from '../GameHostingProvider';
import { useOndOpenParticipantExploreLibrary } from '../GameLibrary/useOndOpenParticipantExploreLibrary';
import {
  ConnectedGamePackCard,
  DefaultGamePackCardBadges,
  GamePackCard,
  type GamePackCardStyles,
} from '../GamePack/GamePackCard';
import { useFetchGameSessionGamePack, useOndGameState } from '../hooks';
import { usePlaybackDesc } from '../Playback/PlaybackInfoProvider';
import {
  type PostGameControlState,
  usePostGameControlAPI,
  usePostGameSharedAPI,
} from './Provider';

export function PostGameDisplay(props: {
  scenario: EnumsGamePackRecommendationScenario;
  noPostGameSurvey?: boolean;
}): JSX.Element | null {
  const ondGameState = useOndGameState();
  usePostGameControl(props.scenario);

  if (ondGameState !== 'ended') return null;
  return <PostGameDisplayInternal {...props} />;
}

function PostGameDisplayInternal(props: {
  scenario: EnumsGamePackRecommendationScenario;
  noPostGameSurvey?: boolean;
}) {
  const isCoordinator = useIsCoordinator();
  const [done, setDone] = useState(props.noPostGameSurvey);
  const markDone = useLiveCallback(() => setDone(true));

  return (
    <Layout>
      {!done ? (
        <PostGameSurvey onSkip={markDone} onSubmit={markDone} />
      ) : isCoordinator ? (
        <PostGameDisplayCoordinator />
      ) : (
        <PostGameDisplayParticipant scenario={props.scenario} />
      )}
    </Layout>
  );
}

function PostGameSurvey(props: { onSubmit: () => void; onSkip: () => void }) {
  const { onSubmit, onSkip } = props;
  const hidden = useHiddenFieldsSnapShot();
  const amICohost = useAmICohost();
  const [cohostEnabled] = useState(
    usePlaybackDesc(SessionMode.OnDemand)?.genConfig.v2?.cohostEnabled ?? false
  );
  const widget = useMemo(() => {
    const formId = amICohost
      ? config.misc.typeFormIdCohost
      : cohostEnabled
      ? config.misc.typeFormIdLive2
      : config.misc.typeFormIdOnd;
    return (
      <Widget
        id={formId}
        hidden={hidden}
        className='w-full h-full'
        opacity={0}
        autoResize
        disableScroll
        noScrollbars
        hideFooter
        hideHeaders
        noHeading
        onSubmit={onSubmit}
      />
    );
  }, [amICohost, cohostEnabled, hidden, onSubmit]);

  return (
    <>
      {widget}
      <button
        type='button'
        className='btn text-center text-icon-gray underline text-sms animate-fade-in'
        onClick={onSkip}
      >
        Skip Survey
      </button>
    </>
  );
}

function Layout(props: { children: React.ReactNode }): JSX.Element {
  const ref = useRef<HTMLDivElement | null>(null);
  const { top, height } = useLayoutAnchorRect('lobby-top-spacing-anchor') ?? {
    top: 0,
    height: 0,
  };
  useLayoutEffect(() => {
    if (!ref.current) return;
    ref.current.style.paddingTop = `${top + height}px`;
  }, [top, height]);

  return (
    <div className='fixed inset-0 bg-lp-black-001'>
      <div
        ref={ref}
        className='w-full h-full flex flex-row items-center justify-center'
      >
        <div className='flex-1 min-w-0 px-15 flex flex-col items-center justify-center'>
          {props.children}
        </div>
      </div>
    </div>
  );
}

function PostGameCoordinatorCTA(): JSX.Element {
  const ondUICtrl = useOndGameUIControl();
  const { actionDisabled } = useOndGameUIControlState();
  const analytics = usePostGameAnalytics();
  const {
    justPlayed,
    selected,
    justPlayedMoreUnitsAvailable,
    justPlayedUnitLabel,
  } = useSnapshot(usePostGameControlAPI().state) as PostGameControlState;
  const [reallyDisable, setReallyDisable] = useState(false);
  const pendingDisable = useRef(false);
  const featureChecker = useMyOrganizationFeatureChecker();
  const openUrl = useVenueUrlOpener();
  const inputRef = useRef<HTMLInputElement | null>(null);
  const isCohost = useAmICohost();

  const disabled = !selected || actionDisabled;
  useLayoutEffect(() => {
    if (!disabled) {
      setReallyDisable(false);
      pendingDisable.current = false;
    } else if (!pendingDisable.current) {
      pendingDisable.current = true;
      const timeout = new BrowserTimeoutCtrl();
      timeout.set(() => setReallyDisable(true), 200);
      return () => timeout.clear();
    }
  }, [disabled]);

  const handleStartGame = async () => {
    // We allow the button to remain enabled even when the operation is banned,
    // in order to prevent the UI from unnecessarily flickering/jumping during
    // quick loads. This requires a manual check.
    if (disabled || !selected) return;
    analytics.trackStartGameClicked({
      gamePackId: selected.id,
      gamePackName: selected.name,
      gamePackType: selected.detailSettings?.gameType ?? null,
      source: selected.id === justPlayed?.id ? 'justPlayed' : 'recommended',
    });

    const includeHostedTutorialRef = inputRef.current;
    const skipHostedTutorial =
      includeHostedTutorialRef !== null && // if the ref isn't there, then they cannot skip
      !includeHostedTutorialRef.checked; // when checked, we want to _include_ tutorial

    const useAnimatedRandomization =
      isCohost &&
      getFeatureQueryParamArray('cohost-team-randomization') === 'animated';

    ondUICtrl?.onClickStartGamePlay({
      skipHostedTutorial,
      useAnimatedRandomization,
    });
  };

  const selectedReplayable =
    selected?.id === justPlayed?.id && selected?.replayable;

  const selectedMoreLevelsAvailable =
    selected?.id === justPlayed?.id && justPlayedMoreUnitsAvailable;

  const showUpgrade =
    selected && !featureChecker.canAccessGamePackForOnd(selected);

  const showIncludeHostedTutorial = selected && selected.replayable;

  if (showUpgrade) {
    return (
      <OrgSubscriptionUpgradeButton
        openUrl={openUrl}
        className='w-50 h-10 p-0.5'
      />
    );
  }

  return (
    <div className='flex flex-col items-center'>
      <button
        type='button'
        className='btn-primary w-50 h-10 flex items-center justify-center gap-2'
        disabled={reallyDisable}
        onClick={handleStartGame}
      >
        {reallyDisable && Boolean(selected) ? (
          <Loading text='' imgClassName='w-4 h-4' />
        ) : selectedMoreLevelsAvailable ? (
          <DoubleRightArrow className='w-4 h-4 fill-current' />
        ) : selectedReplayable ? (
          <RefreshIcon className='w-4 h-4 fill-current' />
        ) : null}
        {selectedMoreLevelsAvailable ? (
          <>Start Next {capitalize(justPlayedUnitLabel)}</>
        ) : selectedReplayable ? (
          <>Play Again</>
        ) : (
          <>Start Game</>
        )}
      </button>
      {showIncludeHostedTutorial && (
        <div
          className={`
            text-xs font-medium text-white tracking-wide
            px-1 py-2 mx-2
            flex items-center justify-center gap-1
          `}
        >
          <input
            ref={inputRef}
            type='checkbox'
            className='checkbox-dark'
            defaultChecked={true}
          />
          Include Hosted Tutorial
        </div>
      )}
    </div>
  );
}

export function usePostGameControl(
  scenario: EnumsGamePackRecommendationScenario
) {
  const ondGameState = useOndGameState();
  const ctrl = usePostGameControlAPI();
  const pack = useFetchGameSessionGamePack();
  const isCoordinator = useIsCoordinator();
  const me = useMyInstance();
  const sessionId = useStreamSessionId();

  useEffect(() => {
    if (
      ondGameState !== 'ended' ||
      !isCoordinator ||
      !me?.id ||
      !pack?.id ||
      !sessionId ||
      (ctrl.state.sessionId === sessionId &&
        ctrl.state.recommended &&
        ctrl.state.recommended.length > 0)
    )
      return;
    ctrl.fetchAndSetRecommendations(sessionId, pack.id, me.id, scenario);
  }, [
    ctrl,
    ondGameState,
    isCoordinator,
    sessionId,
    pack?.id,
    me?.id,
    scenario,
  ]);
}

function usePostGameRecommendations(
  scenario: EnumsGamePackRecommendationScenario
): SWRResponse<string[]> {
  const state = useSnapshot(usePostGameSharedAPI().state) as ReturnType<
    typeof usePostGameSharedAPI
  >['state'];
  const sessionId = useStreamSessionId();
  const hasPostGameState = state.sessionId === sessionId;

  const [shouldFallback, setShouldFallback] = useState(false);
  // if a post game state doesn't arrive within 5 seconds, we should just fallback.
  useTimeout(() => setShouldFallback(true), hasPostGameState ? null : 5000);

  // fetch fallback recommendations if we don't have any shared recommendations. this would happen if, for some reason,
  // the coordinator cannot successfully set shared recommendations via the control API.
  const resp = useSWRImmutable(
    shouldFallback ? ['/api/game-packs/recommend', sessionId, scenario] : null,
    async () => {
      const resp = await apiService.gamePack.recommendGamePack({ scenario });
      return resp.data.gamePacks.map((r) => r.id);
    },
    {
      shouldRetryOnError: false,
      fallbackData: state.recommended ?? undefined,
    }
  );

  if (!hasPostGameState && !shouldFallback) {
    return { isLoading: true } as SWRResponse<string[]>;
  } else {
    return resp;
  }
}

function useGamePackCardStyles() {
  const isCoordinator = useIsCoordinator();
  return useMemo<GamePackCardStyles>(() => {
    return {
      border: `border-2 border-transparent`,
      cursor: isCoordinator ? 'cursor-pointer' : 'cursor-auto',
    };
  }, [isCoordinator]);
}

function PostGameRecommendationsRow(props: { packIds: string[] }): JSX.Element {
  const { packIds } = props;
  const shared = usePostGameSharedAPI();
  const state = useSnapshot(shared.state);
  const rowCtrl = useSimpleCarouselControl();
  const styles = useGamePackCardStyles();
  const getMyClientId = useMyClientIdGetter();
  const getCoordinator = useGameHostingCoordinatorGetter();
  const notificationDataSource = useNotificationDataSourceRedux();
  const [requestedGamePacks, setRequestedGamePacks] = useState<Set<string>>(
    new Set()
  );
  const analytics = useOnDGameAnalytics();

  const handleRequest = useLiveCallback(async (pack: GamePack) => {
    if (requestedGamePacks.has(pack.id)) return;

    const requestedByClientId = getMyClientId();
    const toUserClientId = getCoordinator()?.clientId;
    if (!toUserClientId) return;
    notificationDataSource.send({
      id: uuidv4(),
      toUserClientId,
      type: NotificationType.GamePackRequested,
      createdAt: Date.now(),
      metadata: {
        gamePackId: pack.id,
        requestedByClientId,
      },
    });
    analytics.trackExperienceRequested({
      gamePackId: pack.id,
      gamePackName: pack.name,
      source: 'post-game-recommendations',
      ondGameState: 'ended',
    });
    setRequestedGamePacks((prev) => {
      const next = new Set(prev);
      next.add(pack.id);
      return next;
    });
  });

  const first = packIds[0];
  const prevFirst = usePrevious(packIds[0]);

  useLayoutEffect(() => {
    if (prevFirst !== first) rowCtrl.resetScroll();
  }, [prevFirst, first, rowCtrl]);

  const justPlayedStyles = { ...styles };
  if (state.justPlayed && state.justPlayed === state.selected) {
    justPlayedStyles.border = 'border-2 border-primary';
  }

  return (
    <SimpleCarousel gapWidth={8} cardWidth={248} control={rowCtrl}>
      {packIds.map((gamePackId) => {
        const cardStyles = {
          ...styles,
          border:
            'border-2 border-transparent hover:border-primary transition-colors',
        };
        // eslint-disable-next-line valtio/state-snapshot-rule
        if (gamePackId === state.selected) {
          cardStyles.border = 'border-2 border-primary';
        }

        return (
          <div className='py-4' key={gamePackId}>
            <ConnectedGamePackCard
              gamePackId={gamePackId}
              styles={cardStyles}
              bottomAccessory={(p) => <GamePackCardBottomAccessory pack={p} />}
              hoverAccessory={(pack) => (
                <div className='w-full h-full rounded-2.5xl text-white flex flex-col items-center justify-end gap-1 px-2 pb-2'>
                  <button
                    type='button'
                    className='btn-delete rounded w-full h-10 disabled:opacity-90'
                    onClick={() => handleRequest(pack)}
                    disabled={requestedGamePacks.has(pack.id)}
                  >
                    {requestedGamePacks.has(pack.id)
                      ? 'Sent!'
                      : 'Request Experience'}
                  </button>
                </div>
              )}
            />
          </div>
        );
      })}
    </SimpleCarousel>
  );
}

function MoreUnitsAvailable(props: { unitLabel: string }): JSX.Element {
  return (
    <div className='absolute -bottom-4 left-0 right-0 text-center text-tertiary font-bold'>
      More {pluralize(props.unitLabel)} available
    </div>
  );
}

// attempts to keep a non-null value around, as long as possible.
// this is primarily to avoid flashy ui changes when data is deleted.
function useRetained<T>(val: T | null | undefined): T | null | undefined {
  const [persisted, setPersisted] = useState(val);
  useEffect(() => {
    if (val) setPersisted(val);
  }, [val]);
  return persisted;
}

function useShouldTriggerBookNow() {
  const enabled = useFeatureQueryParam('venue-capacity-check');
  const derivedSettings = useVenueDerivedSettings();
  return Boolean(
    enabled && derivedSettings?.tryItNowEnabled && derivedSettings?.seatCap
  );
}

// this is the coordinator version of the post game upsell trigger.
// it will either trigger:
//  * a book now upsell if the venue/gamepack had an upsell.
//  * an OTP upsell if the gamepack was purchased.
//  * nothing.
function useTriggerPostGameCoordinatorUpsell() {
  const shouldTriggerBookNow = useShouldTriggerBookNow();
  const triggerBookNow = useTriggerBookNowWithGameSessionGamePack();
  const gamePack = useFetchGameSessionGamePack();
  const triggerModal = useAwaitFullScreenConfirmCancelModal();
  const ondUICtrl = useOndGameUIControl();

  return useLiveCallback(async () => {
    if (shouldTriggerBookNow) {
      return triggerBookNow('post-game');
    } else if (gamePack) {
      try {
        const resp = await apiService.gamePack.getOTPUpsell(
          gamePack.id,
          EnumsOneTimePurchaseUpsellPromoCode.OneTimePurchaseUpsellPromoCodePostGame
        );
        const { product, price, trialPeriodDays } = resp.data;

        return await triggerModal({
          kind: 'custom',
          element: (p) => {
            const onComplete = () => {
              p.internalOnConfirm();
              ondUICtrl?.onClickPostGameExploreAll();
            };
            return (
              <OTPUpSellModal
                onComplete={onComplete}
                onCancel={p.internalOnCancel}
                pack={gamePack}
                subscriptionProduct={product}
                subscriptionPrice={price}
                trialPeriodDays={trialPeriodDays}
                promoCode={
                  EnumsOneTimePurchaseUpsellPromoCode.OneTimePurchaseUpsellPromoCodePostGame
                }
                completeBtnLabel='Browse Library'
              />
            );
          },
        });
      } catch (e) {
        if (axios.isAxiosError(e) && e.response?.status === 404) {
          // ignore, no upsell.
        } else {
          throw e;
        }
      }
    }
  });
}

function PostGameDisplayCoordinator(): JSX.Element {
  const ondUICtrl = useOndGameUIControl();
  const ctrl = usePostGameControlAPI();
  const state = useSnapshot(ctrl.state) as typeof ctrl.state;
  const styles = useGamePackCardStyles();
  const analytics = usePostGameAnalytics();
  const rowCtrl = useSimpleCarouselControl();
  const me = useMyInstance();

  // try to keep these values around...
  const justPlayed = useRetained(state.justPlayed);
  const recommended = useRetained(state.recommended);

  useEffectOnce(() => {
    analytics.trackPostGameViewed({
      justPlayed: ctrl.state.justPlayed?.id,
      recommended: ctrl.state.recommended?.map((r) => r.id),
    });
  });

  const hasTriggeredUpsell = useRef(false);
  const triggerPostGameUpsell = useTriggerPostGameCoordinatorUpsell();
  useEffect(() => {
    if (hasTriggeredUpsell.current || !state.sessionId) return;
    hasTriggeredUpsell.current = true;
    triggerPostGameUpsell();
  }, [state.sessionId, triggerPostGameUpsell]);

  const first = recommended?.[0];
  const prevFirst = usePrevious(recommended?.[0]);

  useLayoutEffect(() => {
    if (prevFirst !== first) rowCtrl.resetScroll();
  }, [prevFirst, first, rowCtrl]);

  const handleSelectGamePack = (pack: GamePack) => {
    if (state.selected?.id === pack.id || !me) return;
    analytics.trackRecommendationSelected({
      gamePackId: pack.id,
      gamePackName: pack.name,
      gamePackType: pack.detailSettings?.gameType ?? null,
      source: 'recommended',
    });
    ctrl.selectRecommendedPack(pack);
    ondUICtrl?.onClickChooseNextGamePack(pack.id, {
      playHistoryTargetId: me.id,
      subscriberId: me.id,
    });
  };

  const hasInitedSelection = useRef(false);
  useEffect(() => {
    if (
      hasInitedSelection.current ||
      !justPlayed?.id ||
      state.selected?.id ||
      !me?.id
    )
      return;
    hasInitedSelection.current = true;
    // same as above but no analytics
    ctrl.selectRecommendedPack(justPlayed);
    ondUICtrl?.onClickChooseNextGamePack(justPlayed.id, {
      playHistoryTargetId: me.id,
      subscriberId: me.id,
    });
  }, [ctrl, justPlayed, me?.id, ondUICtrl, state.selected?.id]);

  const handleExploreAll = () => {
    analytics.trackExploreAllClicked();
    ondUICtrl?.onClickPostGameExploreAll();
  };

  const justPlayedStyles = { ...styles };
  if (justPlayed && justPlayed.id === state.selected?.id) {
    justPlayedStyles.border = 'border-2 border-primary';
  }

  if (state.loading) {
    return (
      <div className='fixed inset-0 bg-lp-black-001'>
        <div className='w-full h-full flex flex-col items-center justify-center'>
          <Loading text='' />
        </div>
      </div>
    );
  }

  if (state.error || !recommended || recommended.length === 0) {
    return (
      <div className='fixed inset-0 bg-lp-black-001'>
        <div className='w-full h-full flex flex-col items-center justify-center text-white text-center'>
          Oops! Looks like we had some trouble loading recommendations.
          <br />
          Try refreshing or loading a game from our library.
          <button
            type='button'
            className='btn text-primary text-xs md:text-sm font-bold flex items-center gap-1'
            onClick={handleExploreAll}
          >
            Explore All
            <ArrowRightIcon />
          </button>
        </div>
      </div>
    );
  }

  return (
    <>
      <div className='w-full flex gap-15'>
        {justPlayed && (
          <div className='flex-none'>
            <div className='text-white text-base md:text-xl font-bold mb-1'>
              You just played
            </div>
            <div className='py-4 relative'>
              <GamePackCard
                gamePack={justPlayed}
                styles={justPlayedStyles}
                onClick={handleSelectGamePack}
                badges={<DefaultGamePackCardBadges gamePack={justPlayed} />}
              />
              {state.justPlayedMoreUnitsAvailable && (
                <MoreUnitsAvailable unitLabel={state.justPlayedUnitLabel} />
              )}
            </div>
          </div>
        )}

        <div className='relative w-full min-w-0 flex flex-col'>
          <div className='flex items-baseline gap-4 mb-1'>
            <div className='text-white text-base md:text-xl font-bold'>
              You might also like
            </div>
            <button
              type='button'
              className='btn text-primary text-xs md:text-sm font-bold flex items-center gap-1'
              onClick={handleExploreAll}
            >
              Explore All
              <ArrowRightIcon />
            </button>
          </div>
          <div className='w-full relative'>
            <SimpleCarousel gapWidth={8} cardWidth={248} control={rowCtrl}>
              {recommended.map((gamePack) => {
                const cardStyles = { ...styles };
                // eslint-disable-next-line valtio/state-snapshot-rule
                if (gamePack.id === state.selected?.id) {
                  cardStyles.border = 'border-2 border-primary';
                }

                return (
                  <div className='py-4' key={gamePack.id}>
                    <GamePackCard
                      gamePack={gamePack}
                      styles={cardStyles}
                      onClick={handleSelectGamePack}
                      badges={<DefaultGamePackCardBadges gamePack={gamePack} />}
                      bottomAccessory={
                        <GamePackCardBottomAccessory pack={gamePack} />
                      }
                    />
                  </div>
                );
              })}
            </SimpleCarousel>
          </div>
        </div>
      </div>
      <div className='mt-12 h-10'>
        <PostGameCoordinatorCTA />
      </div>
    </>
  );
}

// this is the participant version of the post game upsell trigger.
// it will either trigger:
//  * a book now upsell if the venue/gamepack had an upsell.
//  * nothing.
function useTriggerPostGameParticipantUpsell() {
  const shouldTriggerBookNow = useShouldTriggerBookNow();
  const triggerBookNow = useTriggerBookNowWithGameSessionGamePack();

  return useLiveCallback(() => {
    if (shouldTriggerBookNow) {
      triggerBookNow('post-game');
    }
  });
}

function PostGameDisplayParticipant(props: {
  scenario: EnumsGamePackRecommendationScenario;
}): JSX.Element {
  const shared = usePostGameSharedAPI();
  const state = useSnapshot(shared.state);
  const styles = useGamePackCardStyles();
  const analytics = usePostGameAnalytics();
  const { data, isLoading, error } = usePostGameRecommendations(props.scenario);
  const coordinator = useGameHostingCoordinator();
  const coordinatorParticipant = useParticipant(coordinator?.clientId);
  const coordinatorName = coordinatorParticipant?.firstName ?? 'the organizer';

  useEffectOnce(() => {
    analytics.trackPostGameViewed({
      justPlayed: shared.state.justPlayed,
      recommended: shared.state.recommended,
    });
  });

  const handleExploreLibrary = useOndOpenParticipantExploreLibrary();
  const handleExploreAll = () => {
    analytics.trackExploreAllClicked();
    handleExploreLibrary();
  };

  const hasTriggeredUpsell = useRef(false);
  const triggerPostGameUpsell = useTriggerPostGameParticipantUpsell();
  useEffect(() => {
    if (hasTriggeredUpsell.current || !state.sessionId) return;
    hasTriggeredUpsell.current = true;
    triggerPostGameUpsell();
  }, [state.sessionId, triggerPostGameUpsell]);

  const justPlayedStyles = { ...styles };
  if (state.justPlayed && state.justPlayed === state.selected) {
    justPlayedStyles.border = 'border-2 border-primary';
  }

  if (!isLoading && (error || !data || data.length === 0)) {
    return (
      <div className='fixed inset-0 bg-lp-black-001'>
        <div className='w-full h-full flex flex-col items-center justify-center text-white text-center'>
          Oops! Looks like we had some trouble loading recommendations.
          <div className='text-tertiary font-light'>
            No worries, <span className='font-bold'>{coordinatorName}</span>{' '}
            will pick the next game.
          </div>
        </div>
      </div>
    );
  }

  return (
    <>
      <div className='w-full flex gap-15'>
        {state.justPlayed && (
          <div className='flex-none'>
            <div className='text-white text-base md:text-xl font-bold mb-1'>
              You just played
            </div>
            <div className='py-4 relative'>
              <ConnectedGamePackCard
                gamePackId={state.justPlayed}
                styles={justPlayedStyles}
              />
              {state.justPlayedMoreUnitsAvailable && (
                <MoreUnitsAvailable unitLabel={state.justPlayedUnitLabel} />
              )}
            </div>
          </div>
        )}

        <div className='relative w-full min-w-0 flex flex-col'>
          <div className='flex items-baseline gap-4 mb-1'>
            <div className='text-white text-base md:text-xl font-bold'>
              You might also like
            </div>
            <button
              type='button'
              className='btn text-primary text-xs md:text-sm font-bold flex items-center gap-1'
              onClick={handleExploreAll}
            >
              Explore All
              <ArrowRightIcon />
            </button>
          </div>
          <div className='w-full relative'>
            <PostGameRecommendationsRow packIds={data ?? []} />
          </div>
        </div>
      </div>
      <div className='mt-12'>
        <div className='text-tertiary font-light'>
          Tell <span className='font-bold'>{coordinatorName}</span> what you
          want to play next!
        </div>
      </div>
    </>
  );
}
